import { Button, Grid } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Datepicker } from 'components/reusable/datepicker';
import { ReusableSelect as Select } from 'components/reusable/select';
import { CompanyAccount } from 'models/company-account/company-account.model';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import { adminUsageReport, getAccounts, UsageReport } from 'store/sagas/sagas';
import { isValidDate } from 'utils/validation';

interface RouteParams {
  id: string;
}

interface Filter {
  from: Date | null;
  to: Date | null;
  accountId: string;
}

const initialData: Filter = {
  from: null,
  to: null,
  accountId: ''
};

const normalizeDate = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDay()));
};

export const AdminUsageReports: React.FC<
  RouteComponentProps<RouteParams>
> = () => {
  const { state } = useGlobalState();
  const { asyncDispatch, dispatch } = useGlobalState();

  const [accounts, setAccounts] = useState<CompanyAccount[]>([]);

  const [filter, setFilter] = useState<Filter>(initialData);

  useEffect(() => {
    getAccounts(state.accessToken, null)
      .then(({ accounts: newAccounts }) => {
        setAccounts(newAccounts);
      })
      .catch(() => {
        setAccounts([]);
      });
  }, []);

  const updateFilter = (newFilter: Partial<Filter>) => {
    setFilter({ ...initialData, ...newFilter });
  };

  const setDatesCurrentMonth = () => {
    const now = new Date();
    const from = new Date(now.getFullYear(), now.getMonth());
    const to = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    setDateFilter(from, to);
  };

  const setDatesLastMonth = () => {
    const now = new Date();
    const from = new Date(now.getFullYear(), now.getMonth() - 1);
    const to = new Date(now.getFullYear(), now.getMonth());
    to.setDate(to.getDate() - 1);
    setDateFilter(from, to);
  };

  const setDateFilter = (from: Date, to: Date) => {
    updateFilter({
      ...filter,
      from,
      to
    });
  };

  const handleDateChange = (date: Date | null, prop: string) => {
    updateFilter({
      ...filter,
      [prop]: date && isValidDate(date) ? date : null
    });
  };

  const handleChangeFilters = (
    e: ChangeEvent<{ value: number | string; name: string }>
  ) => {
    updateFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  };

  const downloadUsageReport = () => {
    if (!filter.from || !filter.to) {
      dispatch(
        setMessages({
          value: 'Please select a date range',
          severity: 'error'
        })
      );

      return;
    }

    asyncDispatch(
      adminUsageReport(
        normalizeDate(filter.from),
        normalizeDate(filter.to),
        filter.accountId
      )
    )
      .then((res) => {
        const usageReportCsv = convertUsageReportJsonToCsv(res);
        const filename = `Billing Usage Report from ${res.startDate} to ${res.endDate}.csv`;

        // Create a blob with the data we want to download as a file
        const blob = new Blob([usageReportCsv], { type: 'text/csv' });
        // Create an anchor element and dispatch a click event on it to trigger a download
        const a = document.createElement('a');
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        });

        a.dispatchEvent(clickEvt);
        a.remove();
      })
      .catch(console.error);
  };

  // ordered list of the database columns to include in the report (must match properties in the JSON result)
  const reportColumns = `id, sid, subscription_id, name, total_jobs, idv_job_past_init, crosscheck_job_past_init,
            reverification_job_past_init, idv_billable, crosscheck_billable, darkweb_billable, 
            ip_address_billable, physical_address_billable, aml_billable, idv_included, crosscheck_included,
            darkweb_included, ip_address_included, physical_address_included, aml_included,
            aml_job_past_init, reverification_billable, reverification_included, ssn_ecbsv_billable, 
            ssn_private_billable, dob_billable, dlv_included, dlv_processed, dlv_billable, dlv_samba, dlv_aamva`;

  const columnNames = reportColumns.split(',').map((item) => item.trim());

  const convertUsageReportJsonToCsv = (usageReportJson: UsageReport) => {
    const csvData: [string[], ...string[][]] = [columnNames];
    for (const row of usageReportJson.data) {
      const rowData: string[] = [];
      for (const columnName of columnNames) {
        rowData.push(`"${row[columnName]!}"`);
      }

      csvData.push(rowData);
    }

    return csvData.map((e) => e.join(',')).join('\n');
  };

  return (
    <div>
      <h1>Usage Reports</h1>
      <div className="filters-container">
        <Grid container>
          <Grid container item spacing={3} xs={12}>
            <Grid item xs={3}>
              <Datepicker
                className="g-full-width"
                date={filter.from}
                label="From"
                maxDate={filter.to ?? undefined}
                onChange={(date) => {
                  handleDateChange(date, 'from');
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Datepicker
                className="g-full-width"
                date={filter.to}
                label="To"
                minDate={filter.from ?? undefined}
                onChange={(date) => {
                  handleDateChange(date, 'to');
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                className="g-input-width-16"
                items={accounts}
                label="Account"
                name="accountId"
                onChange={handleChangeFilters}
                value={filter.accountId}
              />
            </Grid>
            <Grid item xs={6}>
              <p>
                Leave account blank to view usage for all accounts, or select an
                account to view usage for each of that account's groups.
              </p>
              <p>
                Please note that all dates are interpreted as{' '}
                <b>
                  <a
                    href="https://www.timeanddate.com/worldclock/timezone/utc"
                    rel="noreferrer"
                  >
                    UTC
                  </a>
                </b>
                .
              </p>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={downloadUsageReport}>Download</Button>
            </Grid>
            <Grid item xs={12}>
              <h3>Initialize Dates:</h3>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={setDatesCurrentMonth}>Current Month</Button>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={setDatesLastMonth}>Previous Month</Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
