import { HttpStatusCode, isAxiosError } from 'axios';
import { Dim } from 'components/account/jobs/image-slider/smart-image';
import {
  JobsApproved,
  JobsCount
} from 'components/account/jobs/job-charts/utils';
import {
  DobRequestData,
  DobResponseData
} from 'components/account/jobs/job-modal/job-modal-tabs/components/dob/dob_model';
import { PrivateSSNRequestData } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/private_ssn/private_request_data_summary';
import { PrivateSSNResponseData } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/private_ssn/private_ssn_response_data_summary';
import { SSNJob } from 'components/account/jobs/job-modal/job-modal-tabs/components/ssn/ssn_model';
import {
  Bundle,
  CreateBundleRequest
} from 'components/admin/account-manager/account-bundles';
import { AuthData } from 'models/auth-data/auth-data.model';
import { TwoFactorData } from 'models/auth-data/two-factor-data.model';
import {
  CompanyAccount,
  CompanyAccountGroup
} from 'models/company-account/company-account.model';
import { InviteApi } from 'models/invite/invite.model';
import { Job, Jobs, JobUpdateReview } from 'models/jobs/jobs';
import {
  LoginData,
  TwoFactorLoginData
} from 'models/signup-data/signup-data.model';
import { User, UserUpdateReview } from 'models/user/user.model';
import { http } from 'services/http';
import {
  Action,
  setAccount,
  setAccountGroups,
  setAccounts,
  setSearchedUsers,
  setUser,
  setUserAccountGroups
} from 'store/actions/actions';
import { StoreProps, StoreState, Subscription } from 'store/reducers/reducer';
import { Filterable as Sanitizable, sanitizeFilters } from 'utils/format';
import { toQueryStringWithArraySupport } from 'utils/url';

const apiUrl = process.env['REACT_APP_API']!;
export interface ActionProps {
  state: StoreState;
  dispatch: (action: Action) => void;
}

export function changeUserInfo(userInfo: {
  firstName: string;
  lastName: string;
}) {
  return async ({
    dispatch,
    state
  }: ActionProps): Promise<{
    firstName: string;
    lastName: string;
  }> => {
    const data = await http<{ user: User }>({
      method: 'PUT',
      url: '/user',
      data: userInfo,
      accessToken: state.accessToken
    });

    dispatch(setUser(data.user));

    return {
      firstName: data.user.firstName,
      lastName: data.user.lastName
    };
  };
}

export function resendVerification(email: string) {
  return async ({ state }: ActionProps): Promise<void> => {
    await http({
      method: 'POST',
      url: '/user/resend-verification',
      data: { email },
      accessToken: state.accessToken
    });
  };
}

export function changePassword(passwords: {
  oldPassword: string;
  newPassword: string;
}) {
  return async ({ state }: ActionProps): Promise<void> => {
    await http({
      method: 'PUT',
      url: '/user/password',
      data: passwords,
      accessToken: state.accessToken
    });
  };
}

export function postUserInvite({
  is2FA,
  isReviewer,
  isDownloader,
  accountGroupId,
  isAccountAdmin,
  firstName,
  lastName,
  email
}: {
  is2FA: boolean;
  email: string;
  isReviewer: boolean;
  isDownloader: boolean;
  isAccountAdmin: boolean;
  accountGroupId?: number | null;
  firstName: string;
  lastName: string;
}) {
  return async ({ state }: ActionProps): Promise<{ user: User }> => {
    return http({
      method: 'POST',
      url: '/accounts/invites',
      data: {
        email,
        accountGroupId,
        isReviewer,
        isDownloader,
        isAccountAdmin,
        firstName,
        lastName,
        is2FA
      },
      accessToken: state.accessToken
    });
  };
}

export function changeAccount(accountId: string) {
  return async ({
    dispatch,
    state
  }: StoreProps): Promise<{
    user: User;
    account: CompanyAccount;
  }> => {
    const data = await http<{
      user: User;
      account: CompanyAccount;
    }>({
      method: 'PUT',
      url: `/accounts/${accountId}`,
      accessToken: state.accessToken
    });

    const { user, account } = data;
    dispatch(setUser(user));
    dispatch(setAccount(account));

    const groupData = await http<{
      accountGroups?: CompanyAccountGroup[];
    }>({
      method: 'GET',
      url: '/user/info',
      accessToken: state.accessToken
    });

    if (groupData.accountGroups) {
      dispatch(setUserAccountGroups(groupData.accountGroups));
    } else {
      dispatch(
        setAccountGroups(
          (await loadAccountGroups(state.accessToken)).accountGroups
        )
      );
    }

    return {
      user,
      account
    };
  };
}

interface EmailConfirmResponse {
  user: User;
  account: CompanyAccount;
  accessToken: string;
}
export function confirmEmail(token: string) {
  return async ({
    dispatch,
    state
  }: StoreProps): Promise<EmailConfirmResponse> => {
    const data = await http<EmailConfirmResponse>({
      method: 'post',
      url: '/user/confirm',
      data: {
        token
      },
      accessToken: state.accessToken
    });

    dispatch(setUser(data.user));
    dispatch(setAccount(data.account));

    return data;
  };
}

export interface AccountGroupUser {
  id: number;
  sid: string;
  accountGroupId: number;
  userId: number;
}

export function getAccountGroupUsers({
  accountGroupId
}: {
  accountGroupId: number;
}) {
  return async ({
    state
  }: StoreProps): Promise<{ users: AccountGroupUser[] }> => {
    return http({
      method: 'get',
      url: `/accounts/groups/${accountGroupId}/users`,

      accessToken: state.accessToken
    });
  };
}

export function updateAccountInfo(data: object) {
  return async ({
    state
  }: StoreProps): Promise<{ updatedAccount: CompanyAccount }> => {
    return http({
      method: 'put',
      url: `/update-account-info`,
      data: { data },
      accessToken: state.accessToken
    });
  };
}

interface AccountTermsUpdate {
  termsVersion: string;
  setupAccountStep: string;
}

export function updateAccountTerms(data: AccountTermsUpdate) {
  return async ({
    state
  }: StoreProps): Promise<{ updatedAccount: CompanyAccount }> => {
    return http({
      method: 'put',
      url: `/accounts/terms/accept`,
      data: {
        termsVersion: data.termsVersion,
        setupAccountStep: data.setupAccountStep
      },
      accessToken: state.accessToken
    });
  };
}

interface CreateAccountData {
  email: string;
  password: string;
  tier: string;
}

export function createAccount(data: CreateAccountData) {
  return async ({ state }: StoreProps): Promise<AuthData | TwoFactorData> => {
    return http({
      method: 'post',
      url: `/accounts`,
      data: { data },
      accessToken: state.accessToken
    });
  };
}

export function createAccountGroup({
  name,
  environment
}: {
  name: string;
  environment?: string;
}) {
  return async ({
    state
  }: StoreProps): Promise<{ accountGroup: CompanyAccountGroup }> => {
    return http({
      method: 'post',
      url: `/accounts/groups`,
      data: { name, environment },
      accessToken: state.accessToken
    });
  };
}

export function deleteAccountGroup({
  accountGroupId
}: {
  accountGroupId: number | string;
}) {
  return async ({ state }: StoreProps): Promise<unknown> => {
    return http({
      method: 'delete',
      url: `/accounts/groups/${accountGroupId}`,

      accessToken: state.accessToken
    });
  };
}

export function linkAccountGroupUser({
  accountGroupId,
  userId,
  remove
}: {
  accountGroupId: number;
  userId: number;
  remove: boolean;
}) {
  return async ({ state }: StoreProps): Promise<unknown> => {
    return http({
      method: remove ? 'delete' : 'post',
      url: `/accounts/groups/${accountGroupId}/users/${userId}`,

      accessToken: state.accessToken
    });
  };
}

interface Permissions {
  isAccountAdmin?: boolean;
  isDownloader?: boolean;
  isDisabled?: boolean;
  isReviewer?: boolean;
  is2FA?: boolean;
}

export function changeAccountPermissions({
  id,
  permissions: { isAccountAdmin, isDisabled, isReviewer, isDownloader, is2FA }
}: {
  id: number;
  permissions: Permissions;
}) {
  return async ({ state }: StoreProps): Promise<{ user: User }> => {
    return http({
      method: 'put',
      url: `/accounts/users/${id}/permissions`,
      data: {
        isAccountAdmin,
        isDownloader,
        isDisabled,
        isReviewer,
        is2FA
      },
      accessToken: state.accessToken
    });
  };
}

export const getAccount = (accessToken: string) => {
  return async ({ dispatch }: StoreProps): Promise<unknown> => {
    const data = await http<{
      account: CompanyAccount;
    }>({
      method: 'GET',
      url: `/account`,
      accessToken
    });

    dispatch(setAccount(data.account));

    return data;
  };
};

const getAccountDirect = async (accessToken: string) => {
  return http<{
    account: CompanyAccount | null;
  }>({
    method: 'GET',
    url: `/account`,
    accessToken
  });
};

export function getAccountUsers() {
  return async ({ state }: ActionProps): Promise<{ users: User[] }> => {
    return http({
      method: 'GET',
      url: '/accounts/users',
      accessToken: state.accessToken
    });
  };
}

export function adminLoadAccount(id: string) {
  return async ({ state }: ActionProps): Promise<CompanyAccount> => {
    const data = await http<{ account: CompanyAccount }>({
      method: 'GET',
      url: `/admin/accounts/${id}`,
      accessToken: state.accessToken
    });

    return data.account;
  };
}

type Rect = [number, number, number, number];

export interface ExtractionField {
  match_whole_line?: boolean;
  rect: Rect;
  match?: string;
  regex: string | null;
}

export interface Word {
  counts: number[];
  images?: boolean[];
  match?: unknown[] | null;
  rects: Rect[];
  weights: number[];
  match_whole_line?: boolean;
}

export interface Configuration {
  aspect_ratio: number;
  doc_id: string;
  extraction_fields: Record<string, ExtractionField>;
  has_MRZ: boolean;
  has_PDF417: boolean;
  require_side: 'back' | 'front';
  template_name: string;
  thresh: number;
  words: Record<string, Word>;
  has_PDF417_back?: boolean;
  pdf417Reconcile?: boolean;
  lastname_first?: boolean;
  comma_separated_name?: boolean;
  case_separated_name?: boolean;
  side?: string;
}

export interface TemplateItem {
  state: string;
  country: string;
  type: string;
  version: string;
  id: number;
  sid: string;
  photo_url: string | undefined;
  photo_dimensions: Dim;
  live_configuration: Configuration | null;
  configuration: Configuration | null;
  active: boolean;
}

export function adminLoadTemplate(id: string) {
  return async ({ state }: ActionProps): Promise<TemplateItem> => {
    return http({
      method: 'GET',
      apiService: `${apiUrl}/admin/templates/${id}`,
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
  };
}

export function adminUpdateAccount(id: number, data: object) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'PUT',
      url: `/admin/accounts/${id}`,
      accessToken: state.accessToken,
      data
    });
  };
}

export function adminUpdateTemplateConfiguration(data: object) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'PUT',
      apiService: `${apiUrl}/admin/templates/update-template-configuration`,
      headers: {
        'X-Auth-Token': state.accessToken
      },
      data
    });
  };
}

export function adminDeleteTemplate(id: number) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'DELETE',
      apiService: `${apiUrl}/admin/templates/${id}`,
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
  };
}

export function adminReloadTemplates() {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'DELETE',
      apiService: `${apiUrl}/admin/templates`,
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
  };
}

export function adminPromoteTemplate(sid: string) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'POST',
      apiService: `${apiUrl}/admin/templates/promote-template/${sid}`,
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
  };
}

export function adminUpdateUser(data: object) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'PUT',
      url: `/admin/user/`,
      accessToken: state.accessToken,
      data
    });
  };
}

export function loadAccounts() {
  return async ({
    dispatch,
    state
  }: ActionProps): Promise<CompanyAccount[]> => {
    const { accounts } = await getAccounts(state.accessToken, null);
    dispatch(setAccounts(accounts));
    return accounts;
  };
}

interface GetAccountsFilter {
  accountId?: string;
  name?: string;
  selfServe?: string;
  page?: number;
  pageSize?: number;
  isDisabled?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  sid?: string;
  email?: string;
  parents?: string;
}

export async function getAccounts(
  accessToken: string,
  filter: GetAccountsFilter | null = null
): Promise<{ total: number; accounts: CompanyAccount[] }> {
  const params = filter
    ? toQueryStringWithArraySupport(
        filter as unknown as Record<string, string | null>
      )
    : '';

  return http({
    method: 'GET',
    url: `/accounts?${params}`,
    accessToken
  });
}

export interface TemplateListItem {
  id: string;
  type: string;
  country: string;
  state: string;
  version: string;
  photoUri: string;
  createdAt: string;
  updatedAt: string;
}

export interface TemplatesFilter {
  page: number;
  pageSize: number;
  version: string;
  country: string;
  state: string;
  type: string;
  id: string;
}

export function loadTemplates(filter?: TemplatesFilter) {
  return async ({
    state
  }: ActionProps): Promise<{
    total: number;
    templates: TemplateListItem[];
  }> => {
    let params = '';
    if (filter) {
      params = toQueryStringWithArraySupport(
        sanitizeFilters(filter as unknown as Sanitizable)
      );
    }

    if (!params) {
      params = '';
    }

    return http({
      method: 'GET',
      url: `/admin/templates?${params}`,
      accessToken: state.accessToken
    });
  };
}

const loadAccountGroups = async (
  accessToken: string
): Promise<{ accountGroups: CompanyAccountGroup[] }> => {
  return http({
    method: 'get',
    url: `/accounts/groups`,
    accessToken
  });
};

export function getUserInfo() {
  return async ({ dispatch, state }: ActionProps): Promise<unknown> => {
    const data = await http<{
      user: User;
      accountGroups?: CompanyAccountGroup[];
    }>({
      method: 'GET',
      url: '/user/info',
      accessToken: state.accessToken
    });

    dispatch(setUser(data.user));
    dispatch(setUserAccountGroups(data.accountGroups ?? []));

    if (data.user.accountId) {
      const { account } = await getAccountDirect(state.accessToken);

      if (!account) {
        return;
      }

      dispatch(setAccount(account));

      if (!data.accountGroups && data.user.isAccountAdmin) {
        dispatch(
          setAccountGroups(
            (await loadAccountGroups(state.accessToken)).accountGroups
          )
        );
      }
    }

    return data.user;
  };
}

export function getGeocode(address: string) {
  return async ({
    state
  }: ActionProps): Promise<{ result: google.maps.LatLngLiteral } | null> => {
    return http({
      method: 'POST',
      url: '/google/geocode',
      data: { address },
      accessToken: state.accessToken
    });
  };
}

export interface AMLData {
  associatedJobId: string;
  hits?: {
    matches: Record<
      string,
      {
        type: string;
        url: string;
        name: string;
        listing_started_utc: string;
        listing_ended_utc?: string;
      }[]
    >;
    countries?: string[];
    aka?: string[];
    politicalPositions?: string[];
  };
  dob?: string;
  id: string;
  submittedTerm?: string;
  submitted_term?: string;
}
export interface AMLJobResult {
  responseData: string;
  requestData: string;
  errors?: string | null;
  warnings?: string | null;
  confidence?: {
    normalized?: number;
  };
  pdf: string;
  id: string;
  status?: string;
  type: string;
  data?: AMLData;
}

export const removeJob = (id: string, isSandboxMode = false) => {
  return async ({ state }: ActionProps): Promise<void> => {
    await http({
      method: 'DELETE',
      apiService: `${apiUrl}/jobs/${id}`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      isSandbox: isSandboxMode
    });
  };
};

interface DownloadJobResult {
  pdf: string;
  id: string;
  review: boolean;
  confidences: boolean;
}

export const downloadJob = (
  id: string,
  confValue: boolean,
  isSandboxMode: boolean
) => {
  return async ({ state }: ActionProps): Promise<DownloadJobResult> => {
    const params = toQueryStringWithArraySupport({ confidences: confValue });
    return http({
      method: 'GET',
      apiService: `${apiUrl}/jobs/${id}/download?${params}`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      isSandbox: isSandboxMode
    });
  };
};

export const retrieveAamvaDetailed = (id: string, isSandboxMode: boolean) => {
  return async ({ state }: ActionProps): Promise<AMLJobResult> => {
    return http({
      method: 'GET',
      apiService: `${apiUrl}/jobs/${id}/aamva`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      isSandbox: isSandboxMode
    });
  };
};

export const retrieveAmlDetailed = (id: string, isSandboxMode: boolean) => {
  return async ({ state }: ActionProps): Promise<AMLJobResult | undefined> => {
    if (state.sandboxToggled) return;
    return http<AMLJobResult>({
      method: 'GET',
      apiService: `${apiUrl}/aml?id=${id}`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      isSandbox: isSandboxMode
    }).catch((error: Error) => {
      if (
        isAxiosError(error) &&
        error.response?.status === HttpStatusCode.NotFound
      ) {
        return undefined;
      }

      throw error;
    });
  };
};

interface JobsFilter {
  aamvaSource?: string;
  accountGroupSid?: string;
  accountId?: string;
  accountReviewed?: string;
  aml?: string;
  billable?: string;
  billableType?: string;
  email?: string;
  firstName?: string;
  from?: Date | null;
  ids?: string[] | string;
  includeResults?: boolean;
  is_disabled?: string;
  lastName?: string;
  page?: number;
  pageSize?: number;
  phone?: string;
  respIdCountry?: string;
  respIdState?: string;
  respIdType?: string;
  sortBy?: string;
  sortOrder?: string;
  status?: string;
  success?: string;
  tableView?: number;
  to?: Date | null;
  type?: string;
  withPhotoUrls?: boolean;
}

export function loadJobs(
  filters: JobsFilter,
  isAdmin: boolean | null | undefined,
  isSandboxMode: boolean | null | undefined = false
) {
  return async ({ state }: ActionProps): Promise<Jobs> => {
    filters = { ...filters };
    // normalize id type
    if (filters.respIdState) {
      filters.respIdState = filters.respIdState.toUpperCase();
    }

    if (filters.respIdCountry) {
      filters.respIdCountry = filters.respIdCountry.toUpperCase();
    }

    if (filters.respIdType) {
      filters.respIdType = filters.respIdType
        .split(' ')
        .map((m) => m.toLowerCase())
        .join('-');
    }

    const sanitizedFilters = sanitizeFilters(filters as Record<string, string>);

    const params = toQueryStringWithArraySupport(sanitizedFilters);
    return http({
      method: 'GET',
      apiService: `${apiUrl}${isAdmin ? '/admin' : ''}/jobs?${params}`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      isSandbox: isSandboxMode
    });
  };
}

export function loadJob(
  id: string,
  isAdmin: boolean | null | undefined,
  isSandboxMode: boolean | null | undefined = false
) {
  return async ({ state }: ActionProps): Promise<Job> => {
    return http({
      method: 'GET',
      apiService: `${apiUrl}${
        isAdmin ? '/admin' : ''
      }/jobs/${id}?withPhotoUrls=true`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      isSandbox: isSandboxMode
    });
  };
}

export function updateReviewStatus(
  body: UserUpdateReview,
  isSandboxMode: boolean
) {
  return async ({ state }: ActionProps): Promise<Job> => {
    return http({
      method: 'PUT',
      apiService: `${apiUrl}/jobs/${body.id}/review/status`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      data: body,
      isSandbox: isSandboxMode
    });
  };
}

export function updateReview(
  id: string,
  body: JobUpdateReview | null,
  isSandboxMode: boolean
) {
  return async ({ state }: ActionProps): Promise<Job> => {
    return http({
      method: 'PUT',
      apiService: `${apiUrl}/jobs/${id}/review`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      data: body ?? undefined,
      isSandbox: isSandboxMode
    });
  };
}

export function getSSNJob(id: string) {
  return async ({ state }: ActionProps): Promise<SSNJob[]> => {
    return http({
      method: 'GET',
      apiService: `${apiUrl}/ecbsv-ssn/jobs/${id}`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
  };
}

export interface PrivateSSNJob {
  request_data?: PrivateSSNRequestData;
  response_data?: PrivateSSNResponseData;
}

export function getPrivateSSNJob(id: string) {
  return async ({ state }: ActionProps): Promise<PrivateSSNJob> =>
    http({
      method: 'GET',
      apiService: `${apiUrl}/private-ssn/jobs/${id}`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
}

export interface DobJob {
  requestData?: DobRequestData;
  responseData?: DobResponseData;
}

export function getDobJob(id: string) {
  return async ({ state }: ActionProps): Promise<DobJob> =>
    http({
      method: 'GET',
      apiService: `${apiUrl}/dob/jobs/${id}`,
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
}

export function loadUserInvites() {
  return async ({ state }: ActionProps): Promise<{ users: User[] }> => {
    return http({
      method: 'GET',
      url: `/accounts/invites`,
      accessToken: state.accessToken
    });
  };
}

export interface Candidate {
  status?: string;
  firstName: string;
  lastName: string;
  accountGroupSid: string;
  internalId: string;
  id: string;
  phone: string;
  email: string;
  contact: string;
  updatedAt: string;
}

export interface LoadInvitesResponse {
  total: number;
  totalPages: number;
  items: Candidate[];
}

export interface InvitesFilter {
  pageSize?: number;
  page?: number;
  id?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  accountGroupSid?: string | null;
}

export function loadInvites(filters: InvitesFilter) {
  return async ({ state }: ActionProps): Promise<LoadInvitesResponse> => {
    const params = toQueryStringWithArraySupport(
      sanitizeFilters(filters as Record<string, string>)
    );

    return http({
      method: 'GET',
      apiService: `${apiUrl}/invites?${params}`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
  };
}

export function postInvite(invite: InviteApi) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    if (!invite.send) {
      invite.contact = null;
    }

    if (!invite.internalId) {
      invite.internalId = null;
    }

    return http({
      method: 'POST',
      apiService: `${apiUrl}/invites`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      },
      data: invite
    });
  };
}

export function updateKey(
  type: 'private' | 'public' | 'signature',
  accountGroupId: number | null,
  environment = 'live'
) {
  return async ({ state }: ActionProps): Promise<{ key: string }> => {
    return http({
      method: 'POST',
      url: `/accounts/key`,
      accessToken: state.accessToken,
      data: {
        accountGroupId,
        type,
        environment
      }
    });
  };
}

export function deleteKey(type: 'signature') {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'DELETE',
      url: `/accounts/key/${type}`,
      accessToken: state.accessToken
    });
  };
}

export function checkKey(key: string | null, accountGroupId: number | null) {
  return async ({ state }: ActionProps): Promise<{ status: boolean }> => {
    return http({
      method: 'POST',
      url: `/accounts/keycheck`,
      accessToken: state.accessToken,
      data: {
        accountGroupId,
        key
      }
    });
  };
}

export function existKey(type: 'sandbox' | 'signature') {
  return async ({ state }: ActionProps): Promise<{ key: boolean }> => {
    return http({
      method: 'GET',
      url: `/accounts/key/${type}`,
      accessToken: state.accessToken
    });
  };
}

export function removeUserInvite(id: number) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'DELETE',
      url: `/accounts/invites/${id}`,
      accessToken: state.accessToken
    });
  };
}

export function acceptInvite(token: string, password: string) {
  return async ({ state }: ActionProps): Promise<AuthData> => {
    return http({
      method: 'PUT',
      url: '/accounts/invites/accept',
      data: { token, password },
      accessToken: state.accessToken
    });
  };
}

export function resendInvite(id: string) {
  return async ({ state }: ActionProps): Promise<void> => {
    await http({
      method: 'POST',
      apiService: `${apiUrl}/invites/${id}/resend`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
  };
}

export function linkUserInvite(id: number) {
  return async ({ state }: ActionProps): Promise<{ url: string }> => {
    return http({
      method: 'GET',
      url: `/accounts/invites/${id}/link`,
      accessToken: state.accessToken
    });
  };
}

export function resendUserInvite(id: number) {
  return async ({ state }: ActionProps): Promise<{ user: User }> => {
    return http({
      method: 'PUT',
      url: `/accounts/invites/${id}/resend`,
      accessToken: state.accessToken
    });
  };
}

export function copyInvite(id: string) {
  return async ({ state }: ActionProps): Promise<{ message: string }> => {
    return http({
      method: 'POST',
      apiService: `${apiUrl}/invites/${id}/copy`,
      url: 'url',
      headers: {
        'X-Auth-Token': state.accessToken
      }
    });
  };
}

interface LoginResponse {
  resToken: string;
  account: CompanyAccount;
  accessToken: string;
  user: User;
}

export function login(data: LoginData) {
  return async (): Promise<LoginResponse | TwoFactorData> => {
    return http({
      method: 'POST',
      url: '/login',
      data
    });
  };
}

export function twoFactorLogin(data: TwoFactorLoginData) {
  return async (): Promise<AuthData> => {
    return http({
      method: 'POST',
      url: '/verify-2fa',
      data
    });
  };
}

interface ForgotPasswordResponse {
  data: string;
}

export function forgotPassword(email: string) {
  return async (): Promise<ForgotPasswordResponse> => {
    return http({
      method: 'POST',
      url: '/user/reset-password',
      data: { email }
    });
  };
}

interface PasswordResetData {
  password: string;
  token: string;
  sid: string;
  recaptchaToken?: string | null;
}

export function resetPassword(data: PasswordResetData) {
  return async (): Promise<AuthData> => {
    return http({
      method: 'POST',
      url: '/user/password',
      data
    });
  };
}

export function addPaymentMethod(paymentMethodData: object) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'POST',
      url: '/payments/add_payment_method',
      data: paymentMethodData,
      accessToken: state.accessToken
    });
  };
}

export function deletePaymentMethod(paymentMethodId: string) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'DELETE',
      url: '/payments/delete_payment_method',
      data: { paymentMethodId },
      accessToken: state.accessToken
    });
  };
}

export interface PaymentMethod {
  id: string;
  card: {
    last4: string;
  };
}

export function getPaymentMethods(): (
  act: ActionProps
) => Promise<PaymentMethod[]> {
  return async ({ state }: ActionProps) => {
    const paymentMethods = await http<{ paymentMethods: PaymentMethod[] }>({
      method: 'GET',
      url: '/payments/get_payment_methods',
      accessToken: state.accessToken
    });

    return paymentMethods.paymentMethods;
  };
}

export function setPaymentMethodDefault(paymentMethodId: string) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'PUT',
      url: '/payments/set_payment_method_default',
      data: { paymentMethodId },
      accessToken: state.accessToken
    });
  };
}

export function getCurrentSubscriptionObject() {
  return async ({ state }: ActionProps): Promise<Subscription> => {
    return http({
      method: 'GET',
      url: '/payments/subscription',
      accessToken: state.accessToken
    });
  };
}

export interface CustomerInfo {
  invoice_settings: {
    default_payment_method: string;
  };
}

export function getCurrentCustomer() {
  return async ({ state }: ActionProps): Promise<CustomerInfo> => {
    return http<CustomerInfo>({
      method: 'GET',
      url: '/payments/customer',
      accessToken: state.accessToken
    });
  };
}

export interface Invoice {
  id: string;
  total: number;
  invoice_pdf: string;
  hosted_invoice_url: string;
  created: number;
  number: number;
  status: string;
}

export function getInvoices() {
  return async ({ state }: ActionProps): Promise<Invoice[]> => {
    const invoices = await http<{ invoices: Invoice[] }>({
      method: 'GET',
      url: '/payments/get_invoices',
      accessToken: state.accessToken
    });

    return invoices.invoices;
  };
}

export function toggleAutoPay(collectionMethod: string) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    let newCollectionMethod;

    if (collectionMethod === 'charge_automatically') {
      newCollectionMethod = 'send_invoice';
    } else if (collectionMethod === 'send_invoice') {
      newCollectionMethod = 'charge_automatically';
    } else {
      throw new Error('Collection method is invalid');
    }

    return http({
      method: 'PUT',
      url: '/payments/toggle_auto_pay',
      data: { collectionMethod: newCollectionMethod },
      accessToken: state.accessToken
    });
  };
}

export function cancelSubscription() {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'POST',
      url: '/payments/cancel_subscription',
      accessToken: state.accessToken
    });
  };
}

export function reactivateSubscription() {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'POST',
      url: '/payments/reactivate_subscription',
      accessToken: state.accessToken
    });
  };
}

export function loadAccountBundles(accountId: string) {
  return async ({ state }: ActionProps): Promise<Bundle[]> => {
    return http({
      method: 'GET',
      url: `/admin/get_accounts_bundles/${accountId}`,
      accessToken: state.accessToken
    });
  };
}

export function createBundle(accountId: string, data: CreateBundleRequest) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'POST',
      url: `/admin/get_accounts_bundles/${accountId}`,
      accessToken: state.accessToken,
      data
    });
  };
}

export function deleteBundle(bundleId: number) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'DELETE',
      url: `/admin/bundles/${bundleId}`,
      accessToken: state.accessToken
    });
  };
}

export function adminCreateAccount(data: object) {
  return async ({ state }: ActionProps): Promise<CompanyAccount> => {
    return http({
      method: 'POST',
      url: `/admin/accounts`,
      accessToken: state.accessToken,
      data
    });
  };
}

export function adminCreateTemplate(data: object) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'POST',
      apiService: `${apiUrl}/admin/templates`,
      headers: {
        'X-Auth-Token': state.accessToken
      },
      data
    });
  };
}

export interface UsersFilter {
  firstName: string;
  lastName: string;
  email: string;
  page: number;
  isAccountAdmin: string;
  isAdmin: string;
  isSupport: string;
  isReviewer: string;
  isDownloader: string;
  selfServe: string;
  isDisabled: string;
  pageSize: number;
}

export function loadUsersBySearch(search: UsersFilter) {
  return async ({
    dispatch,
    state
  }: ActionProps): Promise<{
    total: number;
    users: User[];
  }> => {
    const sanitized = sanitizeFilters(search as unknown as Sanitizable);

    const params = toQueryStringWithArraySupport(sanitized);

    const data = await http<{ total: number; users: User[] }>({
      method: 'GET',
      url: `/users/search?${params}`,
      accessToken: state.accessToken
    });

    dispatch(setSearchedUsers(data.users));
    return data;
  };
}

export function loadUser(id: string) {
  return async ({ state }: ActionProps): Promise<User> => {
    return http({
      method: 'GET',
      url: `/admin/user/${id}`,
      accessToken: state.accessToken
    });
  };
}

export function updateAccountProperties(id: string, data: object) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'PUT',
      url: `/admin/accounts/${id}/properties`,
      accessToken: state.accessToken,
      data
    });
  };
}

interface AccountProperties {
  configurationProperties?: {
    html: string;
    text: string;
    downloadNoConfidences: string;
    title: string;
    download: string;
    props: string;
  };
}

export function loadAccountProperties(id: string) {
  return async ({ state }: ActionProps): Promise<AccountProperties> => {
    return http({
      method: 'GET',
      url: `/admin/accounts/${id}/properties`,
      accessToken: state.accessToken
    });
  };
}

export interface UsageReport {
  data: Record<string, number | string>[];
  startDate: string;
  endDate: string;
}

export function adminUsageReport(
  from: Date,
  to: Date,
  accountId: string | null = null
) {
  return async ({ state }: ActionProps): Promise<UsageReport> => {
    const params = toQueryStringWithArraySupport(
      sanitizeFilters({
        from,
        to,
        accountId
      })
    );

    return http({
      method: 'GET',
      url: `/payments/usage_report?${params}`,
      accessToken: state.accessToken
    });
  };
}

export function adminResetMeteredUsage(accountId: string) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'PUT',
      url: `/payments/reset_metered_usage`,
      accessToken: state.accessToken,
      data: {
        accountId
      }
    });
  };
}

export function getJobsCountByStatus() {
  return async ({ state }: ActionProps): Promise<JobsCount> => {
    return http({
      method: 'GET',
      url: `/dashboard/jobs/countbystatus`,
      accessToken: state.accessToken
    });
  };
}

export function getJobsApproved() {
  return async ({ state }: ActionProps): Promise<JobsApproved[]> => {
    const res = await http<JobsApproved[]>({
      method: 'GET',
      url: `/dashboard/jobs/approved`,
      accessToken: state.accessToken
    });

    return res;
  };
}

export function getJobs() {
  return async ({ state }: ActionProps): Promise<JobStats[]> => {
    return http({
      method: 'GET',
      url: `/dashboard/jobs`,
      accessToken: state.accessToken
    });
  };
}

export interface JobStats {
  status: number;
  count: number;
  week?: string;
}

export function getJobsVolumeByMonth() {
  return async ({ state }: ActionProps): Promise<JobStats[]> => {
    return http({
      method: 'GET',
      url: `/dashboard/jobs/volumebymonth`,
      accessToken: state.accessToken
    });
  };
}

export function getCreatedJobsSinceLogin() {
  return async ({ state }: ActionProps): Promise<JobStats[]> => {
    return http({
      method: 'GET',
      url: `/dashboard/jobs/lastsevendays`,
      accessToken: state.accessToken
    });
  };
}

export function adminDisableAccount(accountId: string) {
  return async ({ state }: ActionProps): Promise<CompanyAccount> => {
    return http<CompanyAccount>({
      method: 'POST',
      url: `/admin/accounts/${accountId}/disable`,
      accessToken: state.accessToken
    });
  };
}

export function requestUpgrade(accountId: number, requestedTier: string) {
  return async ({ state }: ActionProps): Promise<unknown> => {
    return http({
      method: 'POST',
      url: `/accounts/${accountId}/request-upgrade`,
      accessToken: state.accessToken,
      data: {
        requestedTier
      }
    });
  };
}
